import React,{useEffect, useState} from "react"
import { Button, Card, Accordion, Form } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from "@StarberryUtils"
import './FilterSearch.scss';
// import Technology from '../Technology/'
import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    connectSortBy,
} from "react-instantsearch-dom"
// Search box
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
   
    // sessionStorage.getItem('search')
    return (
       //  <Form.Group className="form-group">
       //      {/* <div className="icon"><Search /></div> */}
       //      <Form.Control id="search" name="search" type="text" placeholder="Notting Hill, London" className="search-location"
       //          onChange={event => refine(event.currentTarget.value)}
       //          value={currentRefinement.replace(/-/g, ' ')}
       //       />
       //  </Form.Group>
               <Form className="tab-form d-md-none">
                 {/* <Form.Control type="Text" placeholder="Technology" className="input-field technology-filed" /> */}
 
                 <Form.Control autoComplete="off" id="search" name="search" type="text" placeholder="Job title, position or location" className="input-field"
                    onChange={event => refine(event.currentTarget.value)}
                    value={currentRefinement.replace(/-/g, ' ')}
                  />
                 <Link className="search">
                   <i className="icon-search">
                   </i>
                 </Link>
               </Form>
    )
 })
 // Search box
const Filter = (props) => {

    return (
        <div className={props.show ? "filter-search" : "hide"}>
            <h2 className="d-lg-none">Filter Your Search</h2>
            <i onClick={props.closeFilter} className="icon-close">

            </i>
            {/* <Form className="tab-form d-md-none">
                                            <Form.Control type="Text" placeholder="Job title, position or location" />
                                            <Link href="#" className="search">
                                                <i className="icon-search">
                                                </i>
                                            </Link>
                                        </Form> */}
                                        <SearchBox/>
                                        <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    Industry
                       mob ile
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                        
                            <RefinementList
                                    attribute="industry"
                                    // searchable
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                    Locations
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                        
                            <RefinementList
                                    attribute="city"
                                    // searchable
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                    Type of Contract
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                        
                            <RefinementList
                                    attribute="job_type"
                                    // searchable
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    
                    </Accordion>
                <div className="btn-wrapper d-md-none">
                    <a className="btn cancel" onClick={props.closeFilter}>
                        cancel
                   </a>
                    <a className="btn btn-primary save" onClick={props.closeFilter}>
                        apply filters
                   </a>
                </div>
        </div>)
};
export default Filter;